import { point } from "@turf/helpers";
import "maplibre-gl/dist/maplibre-gl.css";
import {
  ReactNode,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { renderToStaticMarkup } from "react-dom/server";
import Map, {
  FullscreenControl,
  GeolocateControl,
  Layer,
  LngLat,
  MapGeoJSONFeature,
  MapLayerMouseEvent,
  NavigationControl,
  Popup,
  ScaleControl,
  Source,
  useMap,
} from "react-map-gl/maplibre";
import { KmkId } from "../components/KmkId";
import { Navigation } from "../components/Navigation";
import PageTitle from "../components/PageTitle";
import {
  Source as SourceType,
  useActiveVehicles,
  Vehicle,
} from "../hooks/useActiveVehicles";
import { useStops } from "../hooks/useStops";
import { Category } from "../hooks/useVehicle";
import "./Map2.css";
import { Link } from "react-router-dom";
import { StopLink } from "../components/StopLink";
import { Counter } from "../components/Counter";
import { getFullSourceName } from "../utils";
import { useTicketZones } from "../hooks/useTicketZones";
import {
  ColorSpecification,
  DataDrivenPropertyValueSpecification,
} from "maplibre-gl";

interface VehicleIconProps {
  category: Category;
}

function VehicleIcon({ category }: VehicleIconProps) {
  const color = category === "tram" ? "red" : "blue";

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100}>
      {category === "tram" ? (
        <rect
          x={20}
          y={20}
          width={60}
          height={60}
          stroke={color}
          strokeWidth={2.5}
          strokeOpacity={0.6}
          fill={color}
          fillOpacity={0.6}
        />
      ) : (
        <circle
          cx={50}
          cy={50}
          r={130 / 4}
          stroke={color}
          strokeWidth={2.5}
          strokeOpacity={0.6}
          fill={color}
          fillOpacity={0.6}
        />
      )}
      <polygon points="50,0 65,15 35,15" fill={color} fillOpacity={0.5} />
    </svg>
  );
}

function useMapImage(id: string, element: ReactNode) {
  const mapRef = useMap();

  useLayoutEffect(() => {
    const map = mapRef.current;
    if (map) {
      const img = new Image();
      const markup = renderToStaticMarkup(<>{element}</>);
      img.src = `data:image/svg+xml;base64,${btoa(markup)}`;
      let loaded = false;
      img.onload = () => {
        if (!map.hasImage(id)) {
          map.addImage(id, img);
          loaded = true;
        }
      };
      return () => {
        if (loaded) {
          map.removeImage(id);
        }
      };
    }
  }, [mapRef, id, element]);
}

const tramIcon = <VehicleIcon category="tram" />;
const busIcon = <VehicleIcon category="bus" />;

function VehicleMarkers() {
  const { vehicles } = useActiveVehicles("ttss" as SourceType, 10_000);

  useMapImage("tram", tramIcon);
  useMapImage("bus", busIcon);

  const geojson = useMemo(() => {
    return {
      type: "FeatureCollection",
      features: vehicles
        .filter((v) => v.latitude !== null && v.longitude !== null)
        .map((v) => point([v.longitude!, v.latitude!], v)),
    };
  }, [vehicles]);

  return (
    <Source id="vehicles" type="geojson" data={geojson}>
      <Layer
        id="vehicles"
        type="symbol"
        layout={{
          "text-field": ["get", "route_short_name"],
          "text-font": ["Open Sans Bold"],
          "text-size": ["interpolate", ["linear"], ["zoom"], 6, 10, 15, 16],
          "text-allow-overlap": true,
          "icon-image": ["get", "category"],
          "icon-size": ["interpolate", ["linear"], ["zoom"], 6, 0.25, 15, 0.45],
          "icon-rotate": ["get", "bearing"],
          "icon-allow-overlap": true,
        }}
        paint={{
          "text-color": "white",
          "text-halo-color": [
            "match",
            ["get", "category"],
            "tram",
            "red",
            "bus",
            "blue",
            "black",
          ],
          "text-halo-width": 0.5,
        }}
      />
    </Source>
  );
}

function StopMarkers() {
  const { stops } = useStops();

  const geojson = useMemo(() => {
    return {
      type: "FeatureCollection",
      features: stops.map((s) => point([s.stop_lon, s.stop_lat], s)),
    };
  }, [stops]);

  return (
    <Source id="stops" type="geojson" data={geojson}>
      <Layer
        id="stops-circles"
        type="circle"
        layout={{}}
        paint={{
          "circle-radius": 2,
          "circle-color": "black",
          "circle-opacity": 0.5,
        }}
      />
      <Layer
        id="stops-labels"
        type="symbol"
        layout={{
          "text-field": ["get", "stop_name"],
          "text-font": ["Open Sans Bold"],
          "text-size": 12,
          "text-anchor": "bottom",
          "text-offset": [0, -0.5],
          "text-allow-overlap": true,
        }}
        paint={{
          "text-color": "gray",
          "text-opacity": ["step", ["zoom"], 0, 14, 1],
        }}
      />
    </Source>
  );
}

const ticketZonesColorExpression = [
  "match",
  ["get", "Strefa"],
  "I",
  "dodgerblue",
  "II",
  "limegreen",
  "III",
  "goldenrod",
  "tomato",
] as DataDrivenPropertyValueSpecification<ColorSpecification>;

function TicketZonesPolygons() {
  const { ticketZones } = useTicketZones();

  if (ticketZones === null) {
    return null;
  }

  return (
    <Source id="ticket-zones" type="geojson" data={ticketZones}>
      <Layer
        id="ticket-zones-fill"
        type="fill"
        paint={{
          "fill-color": ticketZonesColorExpression,
          "fill-opacity": ["interpolate", ["linear"], ["zoom"], 9, 0.2, 11, 0],
        }}
      />
      <Layer
        id="ticket-zones-lines"
        type="line"
        paint={{
          "line-color": ticketZonesColorExpression,
          "line-width": 1.5,
          "line-opacity": ["interpolate", ["linear"], ["zoom"], 11, 0.3, 13, 0],
        }}
      />
      <Layer
        id="ticket-zones-labels"
        type="symbol"
        layout={{
          "text-field": ["get", "Nazwa"],
          "text-font": ["Open Sans Italic"],
          "text-size": 11,
          "text-allow-overlap": true,
          "icon-allow-overlap": true,
        }}
        paint={{
          "text-color": "gray",
          "text-opacity": ["interpolate", ["linear"], ["zoom"], 11, 1, 13, 0],
        }}
      />
    </Source>
  );
}

function getDistanceBetweenCoords(
  lat1: number,
  lng1: number,
  lat2: number,
  lng2: number
) {
  const R = 6_371_000;
  const dLat = deg2rad(lat2 - lat1);
  const dLng = deg2rad(lng2 - lng1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLng / 2) *
      Math.sin(dLng / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d;
}

function deg2rad(deg: number) {
  return deg * (Math.PI / 180);
}

function getNearestFeatureProperties<T>(
  features: MapGeoJSONFeature[] | undefined,
  lngLat: LngLat
): T | null {
  if (!features || features.length === 0) {
    return null;
  }

  return features.reduce((prev, curr) =>
    getDistanceBetweenCoords(
      lngLat.lat,
      lngLat.lng,
      curr.properties.latitude,
      curr.properties.longitude
    ) <
    getDistanceBetweenCoords(
      lngLat.lat,
      lngLat.lng,
      prev.properties.latitude,
      prev.properties.longitude
    )
      ? curr
      : prev
  ).properties as T;
}

interface HoveredVehiclePopupProps {
  vehicle: Vehicle | null;
}

function HoveredVehiclePopup({ vehicle }: HoveredVehiclePopupProps) {
  if (
    vehicle === null ||
    vehicle.latitude === null ||
    vehicle.longitude === null
  ) {
    return null;
  }

  return (
    <Popup
      latitude={vehicle.latitude!}
      longitude={vehicle.longitude!}
      anchor="bottom"
      offset={[0, -10] as [number, number]}
      className="hovered-vehicle-popup bold"
      closeButton={false}
      key={`${vehicle.key}_hover`}
    >
      <KmkId
        kmk_id={vehicle.full_kmk_id ?? "?????"}
        link={!!vehicle.full_kmk_id}
      />
    </Popup>
  );
}

interface ClickedVehiclePopupProps {
  vehicle: Vehicle | null;
}

function ClickedVehiclePopup({ vehicle }: ClickedVehiclePopupProps) {
  if (
    vehicle === null ||
    vehicle.latitude === null ||
    vehicle.longitude === null
  ) {
    return null;
  }

  return (
    <Popup
      latitude={vehicle.latitude!}
      longitude={vehicle.longitude!}
      anchor="bottom"
      maxWidth="auto"
      offset={[0, -10] as [number, number]}
      className="clicked-vehicle-popup"
      closeButton={false}
      key={`${vehicle.key}_click`}
    >
      {vehicle.route_short_name !== null && (
        <>
          Linia:{" "}
          <strong>
            <Link
              to={`/routes/${vehicle.route_short_name}`}
              className="hidden-link"
            >
              {vehicle.route_short_name}
            </Link>
          </strong>
          <br />
        </>
      )}
      {vehicle.trip_headsign !== null && (
        <>
          Kierunek:{" "}
          <StopLink
            stopName={vehicle.trip_headsign}
            bold
            expandDepotName
            removeNz
          />
          <br />
        </>
      )}
      {vehicle.full_kmk_id !== null && (
        <>
          Numer taborowy:{" "}
          <strong>
            <KmkId kmk_id={vehicle.full_kmk_id} />
          </strong>
          <br />
        </>
      )}
      {vehicle.shift !== undefined &&
        vehicle.shift !== vehicle.route_short_name && (
          <>
            Brygada: <strong>{vehicle.shift}</strong>
            <br />
          </>
        )}
      <small>
        {vehicle.timestamp !== null && (
          <>
            <Counter timestamp={vehicle.timestamp} /> |{" "}
          </>
        )}
        {getFullSourceName(vehicle.source)}
      </small>
    </Popup>
  );
}

export function Map2() {
  const mapRef = useRef(null);

  const [clickedVehicle, setClickedVehicle] = useState<Vehicle | null>(null);

  const [hoveredVehicle, setHoveredVehicle] = useState<Vehicle | null>(null);

  const onMouseMove = useCallback((event: MapLayerMouseEvent) => {
    // TODO: move popup on position change
    setHoveredVehicle(
      getNearestFeatureProperties<Vehicle>(event.features, event.lngLat)
    );
  }, []);

  const onClick = useCallback((event: MapLayerMouseEvent) => {
    // TODO: move popup on position change
    const maybeFeature = getNearestFeatureProperties<Vehicle>(
      event.features,
      event.lngLat
    );
    setClickedVehicle(maybeFeature);
    if (maybeFeature) {
      // @ts-expect-error
      mapRef.current?.flyTo({
        center: [maybeFeature.longitude!, maybeFeature.latitude!],
        speed: 0.7,
      });
    }
  }, []);

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <PageTitle title="Mapa" />
      <Navigation />
      <Map
        initialViewState={{
          latitude: 50.05,
          longitude: 19.94,
          zoom: 12,
        }}
        bearing={0}
        pitchWithRotate={false}
        mapStyle="https://basemaps.cartocdn.com/gl/positron-gl-style/style.json"
        onMouseMove={onMouseMove}
        onClick={onClick}
        interactiveLayerIds={["vehicles"]}
        ref={mapRef}
      >
        <TicketZonesPolygons />
        <StopMarkers />
        <VehicleMarkers />
        <NavigationControl />
        <GeolocateControl />
        <FullscreenControl />
        <ScaleControl />
        <HoveredVehiclePopup vehicle={hoveredVehicle} />
        <ClickedVehiclePopup vehicle={clickedVehicle} />
      </Map>
    </div>
  );
}
